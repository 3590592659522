import { Grid, Paper, Theme, Typography } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import { makeStyles } from '@mui/styles'
import clsx from 'clsx'
import React, { useEffect, useState } from 'react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick-theme.css'
// Import css files
import 'slick-carousel/slick/slick.css'
import 'swiper/css/bundle'

import { useReactiveVar } from '@apollo/client'
import {
  getProfileFromLanguage,
  getProfileIndexFromLanguage,
} from '../../../../../utilityFunctions/getProfile'
import {
  componentsDrawerStateVar,
  selectedAdditionalComponentsVar,
  selectedAdvancedProductFabricVar,
} from '../../../../../store/reactiveVars'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import SectionTitle from '../SectionTitle'
import { getValidAdditionalComponents } from './additionalComponentUtils'
import { useStyles } from './groupWithDrawerStyles'

const AdditionalComponentGroupWithDrawer = ({
  componentGroup,
  locale,
}): JSX.Element => {
  const classes = useStyles() as any
  //=== Crunch data here...
  //const { locale } = useAppContext()
  const groupProfile = getProfileFromLanguage(
    componentGroup?.additional_component_group_profiles,
    locale,
  )
  const [filteredComponents, setFilteredComponents] = useState([])

  const selectedComponents = useReactiveVar(selectedAdditionalComponentsVar)
  // find selected component by group id
  const selectedComponent = selectedComponents.find(
    (c) => c.additionalComponentGroupId === componentGroup.id,
  )
  const componentProfile = getProfileFromLanguage(
    selectedComponent?.additional_component_profiles,
    locale,
  )

  const groupTitle = groupProfile?.name ?? 'Not translated'
  const componentTitle = selectedComponent
    ? componentProfile?.name ?? 'Not translated'
    : ''

  useEffect(() => {
    const additionalComponents = getValidAdditionalComponents(
      componentGroup,
      selectedComponents,
    )

    let newSelectedComponents = [...selectedComponents]

    if (selectedComponent) {
      //check if selected component exist in the filtered components lists
      if (
        !additionalComponents.find(
          (component) => component.id === selectedComponent.id,
        )
      ) {
        newSelectedComponents = newSelectedComponents.filter(
          (component) =>
            component.additionalComponentGroupId !== componentGroup?.id,
        )
      }
    } else {
      if (additionalComponents?.length > 0) {
        newSelectedComponents = [...selectedComponents, additionalComponents[0]]
      }
    }
    // update only if the selected components have changed
    if (
      JSON.stringify(newSelectedComponents) !==
      JSON.stringify(selectedComponents)
    ) {
      selectedAdditionalComponentsVar(newSelectedComponents)
    }

    setFilteredComponents(additionalComponents)
  }, [selectedComponents])

  const componentsCount = filteredComponents?.length ?? 0

  if (componentsCount <= 1) {
    return <></>
  }
  return (
    <Grid id="additionalComponentSelect" item xs={12} lg={12}>
      <section className={classes.section}>
        <div
          className={classes.row}
          onClick={() => {
            componentsDrawerStateVar({
              open: true,
              data: null,
              componentGroup: componentGroup,
            })
          }}
        >
          <div className={classes.rowContentContainer}>
            <div className={classes.imageContainer}>
              {selectedComponent?.image?.src_xs && (
                <img
                  src={selectedComponent?.image?.src_xs ?? ''}
                  loading="lazy"
                  className={classes.componentImage}
                />
              )}
            </div>
            <div>
              <Typography
                component="p"
                variant="caption"
                display="block"
                style={{ fontSize: '1.2em' }}
              >
                {groupTitle} ({componentsCount})
              </Typography>
              <Typography component="p" variant="body2" display="block">
                {componentTitle}
              </Typography>
            </div>
          </div>
          <ChevronRightIcon className={classes.chevronRight} />
        </div>
      </section>
    </Grid>
  )
}

export default AdditionalComponentGroupWithDrawer
