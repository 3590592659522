import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Paper,
  Theme,
  Typography,
} from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import { makeStyles } from '@mui/styles'
import clsx from 'clsx'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import React, { useCallback, useEffect, useRef, useState } from 'react'
// import { ReactSortable } from 'react-sortablejs' // to delete

import { useReactiveVar } from '@apollo/client'
import {
  advancedProductTotalPriceVar,
  selectedAdditionalComponentsVar,
  sofaScaleVar,
} from '../../../../../store/reactiveVars'

import { priceFormatter } from '../../../../../utilityFunctions/priceUtils'
import { checkDiscountValidity } from '../../../../../utilityFunctions/discountValidityCheck'
import { Media } from '../../../../../../lib/artsyFresnel/Media'
import PreviewSelectedFabric from './PreviewSelectedFabric'
import DimensionsBlock from './DimensionsBlock'
import PreviewSelectedAdditionalComponent from './PreviewSelectedAdditionalComponent'
import { imageFallback } from '../../../../../constants/Images'

// Image imports
// ==== CHAIR ICONS =======
import chairWidth from '/public/chair_dimension_icons/width.png'
// import chairLength from '/public/chair_dimension_icons/length.png'
import chairHeight from '/public/chair_dimension_icons/height.png'
import chairArmsRestHeight from '/public/chair_dimension_icons/arms_rest_height.png'
import chairSeatDepth from '/public/chair_dimension_icons/seat_depth.png'
import chairSeatHeight from '/public/chair_dimension_icons/seat_height.png'
// ==== BED ICONS =======
import bedWidth from '/public/bed_dimension_icons/bed_width.png'
import bedHeight from '/public/bed_dimension_icons/bed_height.png'
import bedLength from '/public/bed_dimension_icons/bed_length.png'
import headboardHeight from '/public/bed_dimension_icons/headboard_height.png'
import headboardWidth from '/public/bed_dimension_icons/headboard_width.png'
import layingPartHeight from '/public/bed_dimension_icons/laying_part_height.png'
import mattressWidth from '/public/bed_dimension_icons/mattress_width.png'
import mattressLength from '/public/bed_dimension_icons/mattress_length.png'
// ==== TABLE ICONS =======
import tableWidth from '/public/table_dimension_icons/table_width.png'
import tableHeight from '/public/table_dimension_icons/table_height.png'
import tableLength from '/public/table_dimension_icons/table_length.png'
import tableRadius from '/public/table_dimension_icons/round_table_radius.png'
import tableExtendedLength from '/public/table_dimension_icons/table_extended_length.png'
import { ProductPrice } from '../../../../UtilityComponents/price'
import { getComponentGroupName } from '../../../../../utilityFunctions/getComponentGroupName'
import { useAppContext } from '../../../../../context/appContext'

const useStylesBlock = makeStyles(
  (theme: Theme) =>
    createStyles({
      card: {
        position: 'relative',
      },
      // sofaDrawingBox: {
      //   minHeight: '300px',
      //   maxHeight: '900px',
      //   // height: '300px',
      //   borderLeft: '1px solid #e2e1e0',

      //   pointerEvents: 'none',
      //   // touchAction: 'none',

      // [theme.breakpoints.down('lg')]: {
      //   borderLeft: 'none',
      //   borderTop: '1px solid #e2e1e0',
      // },
      // },
      selectedComponentsBox: {
        borderLeft: '1px solid #e2e1e0',
        padding: 10,

        [theme.breakpoints.down('sm')]: {
          borderLeft: 'none',
          padding: 0,
        },
      },

      detailsRow: {
        background: '#fff',
        borderBottom: '1px solid #e2e1e0',
      },

      specification: {
        borderBottom: '1px solid #e2e1e0',
        padding: 10,
        marginBottom: 15,
      },
    }),
  { name: 'MuiChairConfigurationBlock' },
)

// We make a block for each sofa combination, so they are separate as products
const ConfigurationBlock = ({
  productName,
  discount,
  selectedFabric,
  selectedAddtionalComponents,
  advancedProduct,
  fabricDisabled,
  ...props
}) => {
  const classes = useStylesBlock() as any
  const { t, i18n } = useTranslation(['common'])
  const specifictionText = t('specification-text')
  const appContext = useAppContext()
  const locale = appContext ? appContext.locale : 'en'

  // const additonalComponentGroups = advancedProduct.manufacturer?.additional_component_groups ?? null

  // const withGroupNames = selectedAddtionalComponents.map(component => ({
  //   ...component,
  //   groupName: getComponentGroupName(additonalComponentGroups, component.additionalComponentGroupId, locale)
  // }))


  return (
    <Grid
      container
      justifyContent="space-evenly"
      spacing={0}
      className={classes.detailsRow}
    >
      <Grid item md={7}>
        <ConfigurationDetails
          productName={productName}
          discount={discount}
          advancedProduct={advancedProduct}
        />
      </Grid>
      <Grid item md={5} className={classes.selectedComponentsBox}>
        <Box className={classes.specification}>
          <Typography
            variant="h6"
            component="p"
            style={{ marginRight: '100px' }}
          >
            {specifictionText}
          </Typography>
        </Box>

        {!fabricDisabled && (
          <PreviewSelectedFabric
            selectedAdvancedProductFabric={selectedFabric}
          />
        )}
        {selectedAddtionalComponents.map((component, i) => (
          <PreviewSelectedAdditionalComponent
            key={component.id}
            selectedAdditionalComponent={component}
            discount={discount}
            selectedFabric={selectedFabric}
          />
        ))}
      </Grid>
    </Grid>
  )
}

const useStylesDetailsRow = makeStyles(
  (theme: Theme) =>
    createStyles({
      card: {},
      contentBox: {
        height: '100%',
        position: 'relative',
        padding: '20px',
      },

      dimensionsBox: {
        marginTop: '20px',
      },

      priceBox: {
        // position: 'absolute',
        borderBottom: '1px solid #e2e1e0',
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingBottom: '10px',
      },

      // Price info
      pricesContainer: {
        display: 'flex',
        // gap: theme.spacing(1),
      },
      discoutedPrice: {
        textDecoration: 'line-through',
        marginRight: '10px',
        fontWeight: 400,
        color: theme.palette.text.secondary,
      },

      row: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '2px',
        width: '100%',
        padding: 0,
        '&:last-child': {
          paddingBottom: 0,
        },
      },
      property: {
        width: '50%',
      },
      value: {
        width: '50%',
        textAlign: 'center',
      },
    }),
  { name: 'MuiChairConfigurationDetails' },
)
// We make a block for each sofa combination, so they are separate as products
const ConfigurationDetails = ({
  productName,
  discount,
  advancedProduct,
  ...props
}) => {
  const classes = useStylesDetailsRow() as any

  // each Shape
  // Price (also discount...)

  // Price without discount!
  // const sumPrice = all_shape_prices.reduce((partialSum, a) => partialSum + a, 0)

  const advancedProductTotalPrice = useReactiveVar(advancedProductTotalPriceVar)

  const sumPrice = advancedProductTotalPrice
  const { discounted, discountedPrice } = checkDiscountValidity(
    discount,
    sumPrice,
  )

  // Dimensions
  // ===== DIMENSIONS ======
  let currentDimensions = {
    width: advancedProduct?.advanced_product?.dimensions?.width ?? null,
    length: advancedProduct?.advanced_product?.dimensions?.length ?? null,
    height: advancedProduct?.advanced_product?.dimensions?.height ?? null,
    radius: advancedProduct?.advanced_product?.dimensions?.radius ?? null,
    weight: advancedProduct?.advanced_product?.dimensions?.weight ?? null,
    //--- CHAIR
    seat_height:
      advancedProduct?.advanced_product?.dimensions?.seat_height ?? null,
    seat_depth:
      advancedProduct?.advanced_product?.dimensions?.seat_depth ?? null,
    seat_width:
      advancedProduct?.advanced_product?.dimensions?.seat_width ?? null,
    armrest_height:
      advancedProduct?.advanced_product?.dimensions?.armrest_height ?? null,
    // ---- BED
    headboard_height:
      advancedProduct?.advanced_product?.dimensions?.headboard_height ?? null,
    headboard_width:
      advancedProduct?.advanced_product?.dimensions?.headboard_width ?? null,
    mattress_width:
      advancedProduct?.advanced_product?.dimensions?.mattress_width ?? null,
    mattress_length:
      advancedProduct?.advanced_product?.dimensions?.mattress_length ?? null,
    mattress_height:
      advancedProduct?.advanced_product?.dimensions?.mattress_height ?? null,
    mattress_overlay_height:
      advancedProduct?.advanced_product?.dimensions?.mattress_overlay_height ??
      null,
    lying_part_height:
      advancedProduct?.advanced_product?.dimensions?.lying_part_height ?? null,
    bedbox_height:
      advancedProduct?.advanced_product?.dimensions?.bedbox_height ?? null,
    bedbox_width:
      advancedProduct?.advanced_product?.dimensions?.bedbox_width ?? null,
    bedbox_length:
      advancedProduct?.advanced_product?.dimensions?.bedbox_length ?? null,
    frame_thickness:
      advancedProduct?.advanced_product?.dimensions?.frame_thickness ?? null,
    //---- TABLE
    table_extended_lengh:
      advancedProduct?.advanced_product?.dimensions?.table_extended_lengh ??
      null,
    table_top_thickness:
      advancedProduct?.advanced_product?.dimensions?.table_top_thickness ??
      null,
    table_leg_width:
      advancedProduct?.advanced_product?.dimensions?.table_leg_width ?? null,
    //--- LGIHTS
    shade_height:
      advancedProduct?.advanced_product?.dimensions?.shade_height ?? null,
    shade_radius:
      advancedProduct?.advanced_product?.dimensions?.shade_radius ?? null,
    //---MISC
    leg_height:
      advancedProduct?.advanced_product?.dimensions?.leg_height ?? null,
  }

  // ======================================
  // OVERWRITE DIMENSIONS FROM SELECTED COMPONENTS HERE!

  const selectedAddtionalComponents = useReactiveVar(
    selectedAdditionalComponentsVar,
  )

 

  for (const selectedComponent of selectedAddtionalComponents) {
    //1) Find the group of selected component


    if (selectedComponent?.additionalComponentGroupId) {
      const selectedGroupID = selectedComponent?.additionalComponentGroupId
      // const selectedGroup =
      //   advancedProduct?.advanced_product?.product_container?.manufacturer?.additional_component_groups.filter(
      //     (item) => item.id === selectedGroupID,
      //   ) ?? []

      const selectedGroup =
      advancedProduct?.advanced_product?.additional_component_groups.filter(
        (item) => item.id === selectedGroupID,
      ) ?? []
        

      if (selectedGroup.length) {
        if (
          selectedGroup[0].enable_custom_dimensions_for_components &&
          selectedComponent?.dimensions
        ) {
          const dimensionNamesToOverwrite =
            selectedGroup[0].enabled_dimensions_in_group
          for (const dimensionNameToOverwrite of dimensionNamesToOverwrite) {
            //-- Apply overwrites on --> currentDimensions
            currentDimensions[dimensionNameToOverwrite] =
              selectedComponent?.dimensions[dimensionNameToOverwrite] ?? null
          }
        }
      }
    }
  }

  const dimensionNames = Object.keys(currentDimensions)

  const { t, i18n } = useTranslation(['common'])
  const modelText = t('model-text')
  const partsText = t('parts-text')
  const priceText = t('configuration-price')
  const sleepingFunctionText = t('sleeping-function-text')
  const yesText = t('yes')
  const noText = t('no')

  //=== MAIN IMAGE

  const images = advancedProduct?.advanced_product?.images
    .slice()
    .sort((a, b) => (a.display_order > b.display_order ? 1 : -1))
  const productMainImage = images
    ? images[0]?.src_thumbnail ?? images[0]?.src ?? imageFallback
    : imageFallback

  //=== PRODUCT TYPE
  const advancedProductType =
    advancedProduct?.advanced_product?.advanced_product_type ?? 'OTHER'

  return (
    <Grid container justifyContent="space-evenly" spacing={0}>
      <Grid item xs={12} md={6}>
        <Box className={classes.contentBox}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              marginBottom: '15px',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <Typography variant="h6" component="h4">
              {modelText}
            </Typography>
            <Typography
              variant="h6"
              component="h4"
              style={{
                fontWeight: 300,
                fontSize: '18px',
              }}
            >
              {productName}
            </Typography>
          </Box>

          <Box
            sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}
          >
            <CardMedia
              component="img"
              sx={{ maxWidth: 400 }}
              image={productMainImage}
              alt={productName}
            />
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} md={6}>
        <Box className={classes.contentBox}>
          <Box className={classes.priceBox}>
            <Typography
              variant="h6"
              component="p"
              style={{ marginRight: '100px' }}
            >
              {priceText}
            </Typography>
            <div className={classes.pricesContainer}>
              <Typography
                // fontSize={12}
                variant={discounted ? 'body1' : 'body2'}
                component="p"
                className={clsx(discounted && classes.discoutedPrice)}
              >
                <ProductPrice price={sumPrice} />
              </Typography>
              {discounted && (
                <Typography variant="subtitle1" component="p">
                  {' '}
                  {discountedPrice && <ProductPrice price={discountedPrice} />}
                </Typography>
              )}
            </div>
          </Box>
          <Box className={classes.dimensionsBox}>
            <DimensionsBlock dimensions={currentDimensions} />
          </Box>

          {/* CHAIR DIMENSION PARTS */}
          <Box
            sx={{
              padding: '10px 0',
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
            }}
          >
            {dimensionNames.map((dimensionName, i) => (
              <div key={i}>
                {advancedProductType === 'CHAIR_ARMCHAIR' && (
                  <ChairDimensionsDrawing
                    dimensionName={dimensionName}
                    dimensions={currentDimensions}
                  />
                )}

                {advancedProductType === 'BED' && (
                  <BedDimensionsDrawing
                    dimensionName={dimensionName}
                    dimensions={currentDimensions}
                  />
                )}

                {advancedProductType === 'TABLE' && (
                  <TableDimensionsDrawing
                    dimensionName={dimensionName}
                    dimensions={currentDimensions}
                  />
                )}
              </div>
            ))}
          </Box>
        </Box>
      </Grid>
    </Grid>
  )
}

export const ChairDimensionsDrawing = ({
  dimensionName,
  dimensions,
  ...props
}) => {
  let image = null
  if (dimensionName === 'width') {
    image = chairWidth.src
  }
  // if (dimensionName === 'length') {
  //   image = chairLength.src
  // }
  if (dimensionName === 'height') {
    image = chairHeight.src
  }
  if (dimensionName === 'arm_srest_height') {
    image = chairArmsRestHeight.src
  }
  if (dimensionName === 'seat_depth') {
    image = chairSeatDepth.src
  }
  if (dimensionName === 'seat_height') {
    image = chairSeatHeight.src
  }

  if (!image) return <span></span>

  if (!dimensions[dimensionName]) return <span></span>

  return (
    <Paper elevation={1} sx={{ margin: 1, textAlign: 'center' }}>
      <CardMedia
        component="img"
        sx={{ width: 60 }}
        image={image}
        alt={dimensionName}
      />
      <p>{dimensions[dimensionName]} cm</p>
    </Paper>
  )
}

// bedWidth
// bedHeight
// bedLength
// headboardHeight
// headboardWidth
// layingPartHeight
// mattressWidth
// mattressLength

export const BedDimensionsDrawing = ({
  dimensionName,
  dimensions,
  ...props
}) => {
  let image = null
  if (dimensionName === 'width') {
    image = bedWidth.src
  }
  if (dimensionName === 'length') {
    image = bedLength.src
  }
  if (dimensionName === 'height') {
    image = bedHeight.src
  }
  if (dimensionName === 'headboard_height') {
    image = headboardHeight.src
  }
  if (dimensionName === 'headboard_width') {
    image = headboardWidth.src
  }
  if (dimensionName === 'laying_part_height') {
    image = layingPartHeight.src
  }
  if (dimensionName === 'mattress_width') {
    image = mattressWidth.src
  }
  if (dimensionName === 'mattress_length') {
    image = mattressLength.src
  }

  if (!image) return <span></span>

  if (!dimensions[dimensionName]) return <span></span>

  return (
    <Paper elevation={1} sx={{ margin: 1, textAlign: 'center' }}>
      <CardMedia
        component="img"
        sx={{ width: 73 }}
        image={image}
        alt={dimensionName}
      />
      <p style={{ marginTop: '5px' }}>{dimensions[dimensionName]} cm</p>
    </Paper>
  )
}

// tableWidth
// tableHeight
// tableLength
// tableRadius
// tableExtendedLength

export const TableDimensionsDrawing = ({
  dimensionName,
  dimensions,
  ...props
}) => {
  let image = null
  if (dimensionName === 'width') {
    image = tableWidth.src
  }
  if (dimensionName === 'length') {
    image = tableLength.src
  }
  if (dimensionName === 'height') {
    image = tableHeight.src
  }
  if (dimensionName === 'radius') {
    image = tableRadius.src
  }
  if (dimensionName === 'table_extended_length') {
    image = tableExtendedLength.src
  }

  if (!image) return <span></span>

  if (!dimensions[dimensionName]) return <span></span>

  return (
    <Paper elevation={1} sx={{ margin: 1, textAlign: 'center' }}>
      <CardMedia
        component="img"
        sx={{ width: 70, padding: '5px' }}
        image={image}
        alt={dimensionName}
      />
      <p>{dimensions[dimensionName]} cm</p>
    </Paper>
  )
}

export default ConfigurationBlock
