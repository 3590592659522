import { gql } from '@apollo/client'

export const GET_PRODUCT_PATHS = gql`
  query GET_PRODUCT_PATHS($language: Language) {
    findManyProductContainer(
      take: 100 # We cap the number at 1000 to speed up deployment
      # last: 100
      # orderBy: { single_product: { price: asc } }
      # orderBy: { product_position_in_store: asc }
      orderBy: { SVP: desc }
      where: {
        # featured: { equals: true }
        # categories: { some: { id: { equals: 1 } } }
        OR: [
          {
            single_product: {
              is: {
                product_profiles: { some: { language: { equals: $language } } }
              }
            }
          }
          {
            advanced_product: {
              is: {
                advanced_product_profiles: {
                  some: { language: { equals: $language } }
                }
              }
            }
          }
        ]
      }
    ) {
      id
      type
      single_product {
        product_profiles {
          language
          meta_information {
            id
            permalink
          }
        }
      }
      advanced_product {
        advanced_product_profiles {
          language
          meta_information {
            id
            permalink
          }
        }
      }
    }
  }
`

export const GET_VARIANTS_DRAWER_PRODUCT = gql`
  query GET_VARIANTS_DRAWER_PRODUCT($productID: Int!) {
    findUniqueProductContainer(where: { id: $productID }) {
      id

      createdAt
      discount {
        id
        discount
        active
        startDate
        expiryDate
      }
      dispatch_information {
        id
        manufacturing_time
        shipping_time
        total_delivery_time
      }

      manufacturer {
        id
        is_manufacturer_public
        company_name
        company_email
        company_phone
        country
        website

        default_dispatch_information {
          id
          manufacturing_time
          shipping_time
          total_delivery_time
        }
      }

      single_product {
        id
        price
        sku
        images {
          id
          src
          display_order
        }
        product_profiles {
          id
          name
          size_name
          language
          meta_information {
            id
            permalink
          }
        }
        frame_color {
          id
          background
          hex
        }
        main_color {
          id
          background
          hex
        }
      }
    }
  }
`

// ${PRODUCT_FRAGMENT}
// ...PRODUCT

export const GET_PRODUCT_IN_PRODUCT_PAGE_OLD_URL = gql`
  query GET_PRODUCT_IN_PRODUCT_PAGE($productPermalink: String!) {
    findUniqueProductContainer(
      where: { old_website_url: { contains: $productPermalink } }
    ) {
      id
      old_website_url
      single_product {
        product_profiles {
          language
          meta_information {
            permalink
          }
        }
      }
    }
  }
`

export const GET_PRODUCT_IN_PRODUCT_PAGE = gql`
  query GET_PRODUCT_IN_PRODUCT_PAGE($productPermalink: String!) {
    findUniqueMetaInformation(where: { permalink: $productPermalink }) {
      id
      meta_title
      meta_description
      permalink
      product_profile {
        id
        name
        language
        description
        short_description
        long_product_name
        description_title
        size_name
        assembly
        caring_instructions
        material
        fabric_composition
        foam_type
        filling_type
        single_product {
          id
          price
          sku
          follow_product_stock
          quantity
          minimum_order_quantity
          # //!commenting this will break a lot of stuff
          # product_profiles {
          #   id
          #   name
          #   size_name
          #   language
          #   meta_information {
          #     id
          #     meta_title
          #     meta_description
          #     permalink
          #   }
          # }
          shipping_methods {
            id
            is_enabled
            price_for_box
            shipping_provider
            shipping_method_profiles {
              id
              title
              language
            }
          }
          country_of_origin
          main_color {
            id
            hex
            background
            color_profiles {
              id
              name
              language
            }
          }
          product_details {
            id
            bulb_included
            assembly_instructions
            cable_length
            max_watt_halogen_bulb
            max_watt_led_bulb
            lightbulb_fitting
            bulb_qty
            d3_model
            meta_content
          }
          dimensions {
            id
            width
            height
            length
            radius
            weight
            seat_height
            seat_width
            seat_depth
            armrest_height
            armrest_width
            backrest_width
            fabric_usage
            corner_part_length
            mattress_width
            mattress_length
            mattress_height
            headboard_height
            headboard_width
            table_extended_lengh
            shade_height
            shade_radius
            lying_part_height
            leg_height
            mattress_overlay_height
            bedbox_height
            bedbox_width
            bedbox_length
            frame_thickness
            table_top_thickness
            table_leg_width
          }
          packing_information_list {
            id
            name
            width
            height
            length
            weight
            number_of_products_that_fit_in_box
          }
          images {
            id
            src
            src_lg
            src_md
            src_xs
            display_order
          }
          frame_color {
            id
            color_profiles {
              id
              name
              language
            }
          }
          grouped_by_color {
            id
            price
            product_containerId
            images {
              id
              src
              src
              src_lg
              src_md
              src_xs
              display_order
            }
            product_profiles {
              id
              name
              size_name
              language
            }
            main_color {
              id
              hex
              background
              color_profiles {
                id
                name
                language
              }
            }
            dimensions {
              id
              width
            }
          }
          grouped_by_size {
            id
            price
            product_containerId
            product_profiles {
              id
              name
              size_name
              language
            }
            dimensions {
              id
              width
              height
              length
              radius
              weight
              seat_height
              seat_width
              seat_depth
              shade_height
              shade_radius
            }
          }
          grouped_by_frame_color {
            id
            price
            product_containerId
            product_profiles {
              id
              name
              size_name
              language
            }
            dimensions {
              id
              width
              height
              length
              radius
              weight
              seat_height
              seat_width
              seat_depth
              shade_height
              shade_radius
            }
          }
          product_container {
            id
            visible
            createdAt
            type
            primary_category {
              id
              schema_href
              category_profiles {
                id
                name
                description
                language
              }
            }
            categories {
              id
              schema_href
            }
            style {
              id
              product_containers {
                id
                product_position_in_store
              }
              main_image {
                id
                src
              }
              style_profiles {
                id
                name
                description
                language
                meta_information {
                  id
                  permalink
                }
              }
            }
            collection {
              id
              product_containers {
                id
                product_position_in_store
              }
              main_image {
                id
                src
              }
              collection_profiles {
                id
                name
                description
                language
                meta_information {
                  id
                  permalink
                }
              }
            }

            content_blocks {
              id
              type
              video_link
              video_type
              video_autoplay
              video_loop
              arrangement
              main_image {
                id
                src
              }
              content_block_profiles {
                id
                name
                description
                language
              }
              default_margins
              max_height
              max_width
              min_height
              min_width
              top_margin
              bottom_margin
              left_margin
              right_margin
              background_color
              text_color
              media_max_height
              media_max_width
              media_min_height
              media_min_width
              object_fit_cover
              extra_css
            }
            discount {
              id
              discount
              active
              startDate
              expiryDate
            }
            dispatch_information {
              id
              manufacturing_time
              shipping_time
              total_delivery_time
            }

            manufacturer {
              id
              is_manufacturer_public
              company_name
              company_email
              company_phone
              country
              website

              default_dispatch_information {
                id
                manufacturing_time
                shipping_time
                total_delivery_time
              }
            }
          }
        }
      }

      # ==== FOR ADVANCED PRODUCTS
      advanced_product_profile {
        id
        name
        long_product_name
        description
        language

        advanced_product {
          id
          price_from
          base_price
          advanced_product_type

          fabricCombinations {
            id
            code
            image {
              id
              src_md
            }
            fabricCombinationOptions {
              id
              code
              image {
                id
                src_xs
              }
              fabricCombinationOptionProfiles {
                id
                name
                description
                language
              }
            }
            fabricCombinationProfiles {
              id
              name
              description
              language
            }
          }

          images {
            id
            src
            src_lg
            src_md
            src_xs
            display_order
          }

          product_details {
            id
            bulb_included
            assembly_instructions
            cable_length
            max_watt_halogen_bulb
            max_watt_led_bulb
            lightbulb_fitting
            bulb_qty
            d3_model
            meta_content
          }

          # -------
          # ===== Additional component data related to product
          # --- dimensions
          dimensions {
            id
            width
            height
            length
            radius
            weight
            seat_height
            seat_width
            seat_depth
            armrest_height
            armrest_width
            backrest_width
            fabric_usage
            corner_part_length
            mattress_width
            mattress_length
            mattress_height
            headboard_height
            headboard_width
            table_extended_lengh
            shade_height
            shade_radius
            lying_part_height
            leg_height
            mattress_overlay_height
            bedbox_height
            bedbox_width
            bedbox_length
            frame_thickness
            table_top_thickness
            table_leg_width
          }

          # ==== Getting new fabric information
          advanced_product_price_fabric_category {
            id
            price
            fabrice_price_category {
              id
              group_number
              # Maybe this part is inneficient - reconsider later
              fabric_groups {
                id
              }
            }
          }

          #----- Component to produxt
          additional_component_to_advanced_product {
            id
            extra_price
            enabled
            conditions
            additional_component {
              id
            }
            # ----
            price_fabric_category {
              id
              price
              fabrice_price_category {
                id
                group_number
              }
            }
          }
          #----- Group to produxt
          additional_component_group_to_advanced_product {
            id
            enabled
            additional_component_group {
              id
            }
          }
          # ------- End additional components

          product_container {
            id
            visible
            createdAt
            type
            primary_category {
              id
              schema_href
              category_profiles {
                id
                name
                description
                language
              }
            }
            categories {
              id
              schema_href
            }
            style {
              id
              product_containers {
                id
                product_position_in_store
              }
              main_image {
                id
                src
              }
              style_profiles {
                id
                name
                description
                language
                meta_information {
                  id
                  permalink
                }
              }
            }
            collection {
              id
              product_containers {
                id
                product_position_in_store
              }
              main_image {
                id
                src
              }
              collection_profiles {
                id
                name
                description
                language
                meta_information {
                  id
                  permalink
                }
              }
            }
            content_blocks {
              id
              type
              video_link
              video_type
              video_autoplay
              video_loop
              arrangement
              main_image {
                id
                src
              }
              content_block_profiles {
                id
                name
                description
                language
              }
              default_margins
              max_height
              max_width
              min_height
              min_width
              top_margin
              bottom_margin
              left_margin
              right_margin
              background_color
              text_color
              media_max_height
              media_max_width
              media_min_height
              media_min_width
              object_fit_cover
              extra_css
            }
            discount {
              id
              discount
              active
              startDate
              expiryDate
            }
            dispatch_information {
              id
              manufacturing_time
              shipping_time
              total_delivery_time
            }

            manufacturer {
              id
              is_manufacturer_public
              company_name
              company_email
              company_phone
              country
              website

              default_dispatch_information {
                id
                manufacturing_time
                shipping_time
                total_delivery_time
              }

              # === Get the existing Additional Component Groups
              additional_component_groups {
                id
                ui_type
                code
                use_fabric_prices_for_components
                enable_custom_dimensions_for_components
                enabled_dimensions_in_group
                additional_component_group_profiles {
                  id
                  name
                  description
                  language
                }
                additional_components {
                  id
                  additionalComponentGroupId
                  additional_component_profiles {
                    id
                    name
                    material_name
                    description
                    language
                  }
                  component_sku
                  code
                  color {
                    id
                    hex
                    background
                  }
                  image {
                    id
                    src
                    src_md
                    src_xs
                  }
                  dimensions {
                    id
                    width
                    height
                    length
                    radius
                    weight
                    seat_height
                    seat_width
                    seat_depth
                    armrest_height
                    armrest_width
                    backrest_width
                    fabric_usage
                    corner_part_length
                    mattress_width
                    mattress_length
                    mattress_height
                    headboard_height
                    headboard_width
                    table_extended_lengh
                    shade_height
                    shade_radius

                    lying_part_height
                    leg_height
                    mattress_overlay_height
                    bedbox_height
                    bedbox_width
                    bedbox_length
                    frame_thickness
                    table_top_thickness
                    table_leg_width
                  }
                }
              }
              # --- End manufacturer additional components
            }
          }
          shipping_methods {
            id
            price_for_box
            shipping_method_profiles {
              id
              title
              language
            }
          }
          country_of_origin
        }
      }
    }
  }
`

export const GET_ADDITIONAL_PRODUCT_DATA = gql`
  query GET_ADDITIONAL_PRODUCT_DATA(
    $language: Language
    $productCategories: [Int]!
  ) {
    findManyProductContainer(
      take: 15
      orderBy: { product_position_in_store: asc }
      where: {
        AND: [
          {
            OR: [
              {
                single_product: {
                  is: {
                    product_profiles: {
                      some: { language: { equals: $language } }
                    }
                  }
                }
              }
              {
                advanced_product: {
                  is: {
                    advanced_product_profiles: {
                      some: { language: { equals: $language } }
                    }
                  }
                }
              }
            ]
          }
          { categories: { some: { id: { in: $productCategories } } } }
        ]
      }
    ) {
      id
    }
  }
`

export const GET_ADDITIONAL_ADVANCED_PRODUCT_DATA = gql`
  query GET_ADDITIONAL_ADVANCED_PRODUCT_DATA($id: Int!) {
    findUniqueProductContainer(where: { id: $id }) {
      id

      manufacturer {
        id

        fabric_price_category {
          id
          group_number
          fabric_groups {
            id
            type
            # Fabric icons...
            water_repelant
            virus_protection
            non_flammable
            pet_friendly
            environment_friendly
            easy_water_cleaning
            code
            # actual fabrics
            fabrics {
              id
              code
              order
              image {
                src_md
                src
                src_xs
                src_thumbnail
              }
            }

            fabric_group_profiles {
              name
              language
              description
            }

            #  Not sur if needed
            manufacturer {
              id
              manufacturer_profiles {
                name
                description
                language
              }
              logo_image {
                src_xs
                src
              }
            }
          }
        }
      }

      advanced_product {
        sofa_forms {
          id
          name
          # (below) LCHL, 2AL, etc..
          type
          left_and_right
          dimensions {
            width
            height
            length
            seat_height
            seat_depth
            armrest_width
            backrest_width
            mattress_width
            mattress_length
            corner_part_length
          }

          form_price_fabric_category {
            id
            price
            fabrice_price_category {
              id
              group_number
            }
          }
        }
      }
    }
  }
`
